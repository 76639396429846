export {ButtonComponent} from './ButtonComponent';
export type {ButtonComponentDefinition} from './ButtonComponent';
export {ComponentDefinition, uiSchema} from './ButtonDefinition';
export type {SchemaType} from './ButtonDefinition';
export {ButtonPreviewComponent} from './ButtonPreviewComponent';

export const schema = ComponentDefinition.schema;

import {Registry} from '@backstage-components/base';
import {ButtonModule} from './ButtonModule';
import {ComponentDefinition} from './ButtonDefinition';
Registry.register(ComponentDefinition, ButtonModule);
